import React from "react";

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout pageTitle="404">
    <h1>Página não encontrada</h1>
    <p>A página que você está tentando acessar não existe.</p>
  </Layout>
);

export default NotFoundPage;
